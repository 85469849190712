import { CardContent, Container, Content } from "./styles"
import { faBatteryFull, faPersonBooth, faPlug, faUser } from "@fortawesome/free-solid-svg-icons"
import { Card } from "./card"
import ChargerStatusCard from "src/components/Dashboard/ChargerStatusCard"
import DashboardHeader from "src/components/Dashboard/Header"
import { ExchangePerHour } from "src/components/Dashboard/ExchangePerHour"
import { ForkLiftIcon } from "src/components/ForkliftIcon"
import IndicatorCard from "src/components/Dashboard/IndicatorCard"
import ListFifoBatteries from "src/components/ListFifoBatteries"
import OperationCard from 'src/components/Dashboard/OperationCard'
import React from "react"
import TableChartsDashboard from "src/components/TableChartsDashboard"
import { fetchData } from "src/hooks/useFetch"

function Dashboard() {
	const [data, setData] = React.useState()
	const [loading, setLoading] = React.useState(true)
	const [date, setDate] = React.useState({
		month: new Date().getMonth() + 1,
		year: new Date().getFullYear(),
	})

	const faForklift = ForkLiftIcon({ height: '25px', width: '25px', opacity: 1 })

	React.useEffect(() => {
		const loadData = async () => {
			const response = await fetchData('dashboard')
			setData(response)
			setLoading(false)
		}

		loadData()
	}, [])


	const changeDate = React.useCallback((key, value) => {
		setDate((prev) => ({ ...prev, [key]: value }))
	}, [])

	return (
		<div style={{ position: 'relative', display: 'flex', flexDirection: 'column' }}>
			<DashboardHeader date={date} onChangeDate={changeDate} />
			<Container>
				<Card title="Indicadores de operação" width="60%">
					<CardContent>
						<Content>
							<IndicatorCard title={"Operadores"} value={data?.operator?.total} icon={faUser} loading={loading} />
						</Content>
						<Content>
							<IndicatorCard title={"Colaboradores"} value={data?.employees?.total} icon={faPersonBooth} loading={loading} />
						</Content>
						<Content>
							<IndicatorCard title={"Carregadores"} value={data?.chargers?.total} icon={faPlug} loading={loading} />
						</Content>
						<Content>
							<IndicatorCard title={"Baterias"} value={data?.batteries?.total} icon={faBatteryFull} loading={loading} />
						</Content>
						<Content>
							<IndicatorCard title={"Máquinas"} value={data?.forklifts?.total} icon={faForklift} loading={loading} isCustomIcon={true} />
						</Content>
						<OperationCard date={{ year: date.year, month: date.month }} />
					</CardContent>
				</Card>
				<Card title={"Próximas baterias do FIFO"} width="35%">
					<CardContent>
						<ListFifoBatteries />
					</CardContent>
				</Card>
				<Card title={"Horário de Pico trocas de bateria"} width="60%">
					<ExchangePerHour date={{ year: date.year, month: date.month }} />
				</Card>
				<Card title={"Média de carga operação (H)"} width="35%">
					<ChargerStatusCard />
				</Card>
				<Card title={"Autonomia"} width="100%">
					<TableChartsDashboard
						url={'dashboard/forkliftModelsChart/'.concat(
							`?year=${date.year}&month=${date.month}`,
						)}
						name={'Sub-Operação'}
						title={'Autonomia Média por Sub-Operação'}
					/>

					<TableChartsDashboard
						url={'dashboard/roomModelChart/'.concat(
							`?year=${date.year}&month=${date.month}`,
						)}
						title={'Autonomia Média por Modelo de Máquina'}
						name={'Modelo'}
					/>
					<TableChartsDashboard
						url={'dashboard/batteryRoomChart/'.concat(
							`?year=${date.year}&month=${date.month}`,
						)}
						title={'Autonomia Média por Sala de Bateria'}
						name={'Sala de Bateria'}
						showClient={true}
					/>
				</Card>
			</Container>
		</div>
	)
}

export default Dashboard
