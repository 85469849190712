import { Container, DateContainer, DateDescription, DateRow, DateSelect, DateTitle, Title } from "./styles"

const months = [
	'Janeiro',
	'Fevereiro',
	'Março',
	'Abril',
	'Maio',
	'Junho',
	'Julho',
	'Agosto',
	'Setembro',
	'Outubro',
	'Novembro',
	'Dezembro',
]

const years = Array.from({ length: 10 }, (e, i) => {
	return new Date().getFullYear() - i
})

const DashboardHeader = ({ onChangeDate, date }) => {
	const dateLabel = () => {
		if (date && date.year && date.month) {
			const first = new Date(date.year, date.month - 1, 1).toLocaleDateString('pt-BR', { day: '2-digit', month: '2-digit', year: 'numeric' })
			const last = new Date(date.year, date.month, 0).toLocaleDateString('pt-BR', { day: '2-digit', month: '2-digit', year: 'numeric' })

			return `${first} - ${last}`
		}

		return ''
	}

	return (
		<Container>
			<DateContainer>
				<Title>DASHBOARD</Title>
				<DateContainer>
					<DateDescription>
						Operação no período <br /> {dateLabel()}
					</DateDescription>
				</DateContainer>
			</DateContainer>
			<DateRow>
				<DateContainer>
					<DateTitle>Data de exibição</DateTitle>

					<DateRow>
						<DateSelect value={date.year}
							onChange={(value) => onChangeDate('year', value.target.value)}>
							{years.map((year) => (
								<option key={`year-${year}`} value={year}>
									{year}
								</option>
							))}
						</DateSelect>
						<DateSelect value={months.find((month, index) => index === date.month - 1)}
							onChange={(event) => {
								const monthIndex = months.findIndex(
									(mt) => mt === event.target.value,
								)
								onChangeDate('month', monthIndex + 1)
							}}>
							{months.map((month) => (
								<option key={month} value={month}>
									{month}
								</option>
							))}
						</DateSelect>
					</DateRow>
				</DateContainer>
			</DateRow>
		</Container>
	)
}

export default DashboardHeader