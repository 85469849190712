import {
	convertManufactoryDate,
	createFetchRequest,
	formatManufacturingDate,
	getAutonomyStyle,
} from 'src/genericFunctions'
import {
	faBan,
	faBatteryHalf,
	faCopy,
	faEdit,
	faExclamationTriangle,
	faTools,
	faTrash,
} from '@fortawesome/free-solid-svg-icons'
import { hideLoadingBlur, showLoadingBlur } from 'src/actions/index'
import BatteryCreate from './BatteryCreate'
import BatteryTable from 'src/components/Table'
import BatteryTag from './BatteryTag'
import { Colors } from 'src/styles'
import ConfirmationModal from 'src/components/ConfirmationModal'
import { DisableInfo } from './styles'
import EditBattery from './BatteryEdit'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ForkLiftIcon } from 'src/components/ForkliftIcon'
import InfoModal from 'src/components/InfoModal'
import { MainContentDiv } from 'src/styles/globalStyles'
import MalfunctionModal from 'src/components/MalfunctionModal'
import MonthYearFilter from 'src/components/Filter/MonthYearFilter'
import React from 'react'
import ReactDOM from 'react-dom'
import StatusFilter from 'src/components/Filter/StatusFilter'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import dictionary from 'src/utils/dictionary'
import { getItem } from 'src/utils/localStorage'
import html2canvas from 'html2canvas'
import { jsPDF } from 'jspdf'

async function formatColumns(profileId, orderCallback, statusFilter, lastChecklistFilter) {
	let columns = [
		{
			accessor: 'id',
			Header: <p>ID</p>,
			csvHeader: 'ID',
			width: 'auto',
			priority: 1,
			order: true,
			orderType: 'number',
			orderCallback: orderCallback,
			showFilter: true,
		},
		{
			accessor: 'name',
			Header: dictionary.Name,
			csvHeader: 'Nome',
			width: 'auto',
			priority: 1,
			order: true,
			orderCallback: orderCallback,
			showFilter: true,
		},
		{
			accessor: 'serial',
			Header: dictionary.Serial,
			csvHeader: 'Serial',
			width: 'auto',
			priority: 1,
			order: true,
			orderCallback: orderCallback,
			showFilter: true,
		},
		{
			accessor: 'manufacturing_date',
			Header: (
				<p>
					Data <br />
					de
					<br />
					Fabricação
				</p>
			),
			csvHeader: 'Data de Fabricação',
			width: 'auto',
			priority: 1,
			order: true,
			filterType: 'dateYear',
			orderCallback: orderCallback,
			showFilter: true,
		},
		{
			accessor: 'registered_cycles',
			Header: (
				<p>
					Ciclos
					<br />
					Registrados
				</p>
			),
			csvHeader: 'Ciclos Registrados',
			width: 'auto',
			priority: 1,
			order: true,
			orderCallback: orderCallback,
			showFilter: true,
		},
		{
			accessor: 'estimated_cycles',
			Header: (
				<p>
					Ciclos
					<br />
					Estimados
				</p>
			),
			csvHeader: 'Ciclos Estimados',
			width: 'auto',
			priority: 1,
			order: true,
			orderCallback: orderCallback,
			showFilter: true,
		},
		{
			accessor: 'remaining_cycles',
			Header: (
				<p>
					Ciclos <br /> Restantes
				</p>
			),
			csvHeader: 'Ciclos Restantes',
			width: 'auto',
			priority: 1,
			order: true,
			orderCallback: orderCallback,
			showFilter: true,
		},
		{
			accessor: 'battery_model',
			Header: dictionary.Model,
			csvHeader: 'Modelo',
			width: 'auto',
			priority: 1,
			order: true,
			orderCallback: orderCallback,
			showFilter: true,
		},
		{
			accessor: 'voltage',
			Header: 'Tensão (v)',
			csvHeader: 'Tensão (v)',
			width: 'auto',
			priority: 1,
			showFilter: true,
		},
		{
			accessor: 'forklift_model',
			Header: dictionary.Application,
			csvHeader: 'Aplicação',
			width: 'auto',
			priority: 1,
			order: true,
			orderCallback: orderCallback,
			showFilter: true,
		},
		{
			accessor: 'total_hours',
			Header: (
				<p>
					Horas
					<br />
					Trabalhadas
				</p>
			),
			csvHeader: 'Horas Trabalhadas',
			width: 'auto',
			priority: 1,
			order: true,
			orderCallback: orderCallback,
			showFilter: true,
		},
		{
			accessor: 'total_cycles',
			Header: (
				<p>
					Ciclos
					<br />
					Finalizados
				</p>
			),
			csvHeader: 'Ciclos Finalizados',
			width: 'auto',
			priority: 1,
			order: true,
			orderCallback: orderCallback,
			showFilter: true,
		},
		{
			accessor: 'autonomy',
			Header: dictionary.Autonomy,
			csvHeader: 'Autonomia',
			width: '70px',
			priority: 1,
			order: true,
			orderType: 'number',
			orderCallback: orderCallback,
			showFilter: true,
			getStyle: (values) => getAutonomyStyle(values),
			valueFormatter: (row, value) => {
				return parseFloat(value).toFixed(1).replace('.', ',')
			},
		},
		{
			accessor: 'client',
			Header: dictionary.client,
			csvHeader: 'Cliente',
			showColumn: profileId === 1,
			width: 'auto',
			priority: 1,
			order: true,
			orderCallback: orderCallback,
			showFilter: true,
		},
		{
			accessor: 'branch',
			Header: dictionary.branch,
			csvHeader: 'Sede',
			width: 'auto',
			priority: 1,
			order: true,
			orderCallback: orderCallback,
			showFilter: true,
		},
		{
			accessor: 'status',
			Header: dictionary.Status,
			csvHeader: 'Status',
			width: 'auto',
			priority: 1,
			orderCallback: orderCallback,
			valueFormatter: (row, value) => {
				if (value === 'loading') {
					return <FontAwesomeIcon title="Carregando" icon={faBatteryHalf} />
				}

				if (value === 'maintenance') {
					return <FontAwesomeIcon title="Manutenção" icon={faTools} />
				}

				if (value === 'operation') {
					return (
						<ForkLiftIcon title="Em Operação" color="#000000" opacity={1} />
					)
				}
			},
			showFilter: true,
			customFilter: <StatusFilter onChange={statusFilter.callback} value={statusFilter.value} />
		},
		{
			accessor: 'disabled',
			Header: 'Ativada',
			csvHeader: 'Ativada',
			width: 'auto',
			priority: 1,
			showFilter: true,
			orderCallback: orderCallback,
			filterType: 'yesOrNo',
			valueFormatter: (row, value) => {
				if (value) {
					return <DisableInfo color="#e63b2e">Não</DisableInfo>
				} else {
					return <DisableInfo color="#40987B">Sim</DisableInfo>
				}
			},
		},
		{
			accessor: 'last_checklist',
			Header: (
				<p>
					Data último <br /> Checklist
				</p>
			),
			csvHeader: 'Data último checklist',
			width: 'auto',
			priority: 1,
			orderCallback: orderCallback,
			valueFormatter: (row, value) => {
				if (value) {
					const date = new Date(value)
					const priorDate = new Date(new Date().setDate(date.getDate() - 30))

					if (date >= priorDate) {
						return new Date(value).toLocaleDateString('pt-BR', {
							day: '2-digit',
							month: '2-digit',
							year: 'numeric',
							hour: '2-digit',
							minute: '2-digit',
						})
					} else {
						return (
							<div
								title="O último checklist foi feito há mais de 30 dias"
								style={{
									width: 'auto',
									display: 'flex',
									justifyContent: 'space-around',
									alignItems: 'center',
								}}>
								<label style={{ marginRight: '5px', color: Colors.RED }}>
									{new Date(value).toLocaleDateString('pt-BR', {
										day: '2-digit',
										month: '2-digit',
										year: 'numeric',
										hour: '2-digit',
										minute: '2-digit',
									})}
								</label>
								<FontAwesomeIcon
									color={Colors.RED}
									icon={faExclamationTriangle}
								/>
							</div>
						)
					}
				}

				return (
					<div title="Nenhum checklist realizado para esse equipamento">
						<FontAwesomeIcon color={Colors.RED} icon={faExclamationTriangle} />
					</div>
				)
			},
			showFilter: true,
			customFilter: <MonthYearFilter onChange={lastChecklistFilter.callback} value={lastChecklistFilter.value} />
		},
		{
			accessor: 'mtbf',
			Header: <p>Medium Time <br /> Between <br /> Fails</p>,
			csvHeader: 'Medium Time Betwen Fails',
			width: 'auto',
			priority: 1,
			showFilter: true,
			order: true,
			orderType: 'number',
			orderCallback,
		},
		{
			accessor: 'mttr',
			Header: <p>Medium Time <br /> To <br /> Repair</p>,
			csvHeader: 'Medium Time To Repair',
			width: 'auto',
			priority: 1,
			showFilter: true,
			order: true,
			orderType: 'number',
			orderCallback,
		},
	]

	return columns
}

async function formatRows(rows) {
	const rowsMap = rows.map((row) => {
		let formatDate = convertManufactoryDate(row.manufacturing_date)
		let manufacturingDate = formatManufacturingDate(row.manufacturing_date)

		return {
			...row,
			manufacturing_date_text: manufacturingDate,
			manufacturing_date: formatDate,
		}
	})

	return rowsMap
}

function Batteries({ hideLoadingBlur, showLoadingBlur }) {
	const userInfos = getItem('session')
	if (userInfos === null) window.location.reload()
	const profileId = userInfos.profile_id
	const limit = 100

	const [page, setPage] = React.useState(1)
	const [pagination, setPagination] = React.useState({
		totalPages: 0,
		total: 0,
		page: 1,
	})
	// const [showModal, setShowModal] = React.useState(false)
	const [showModalEdit, setShowModalEdit] = React.useState({
		show: false,
		battery: {},
	})
	const [loading, setLoading] = React.useState(true)
	const [filters, setFilters] = React.useState({
		id: '',
		name: '',
		serial: '',
		manufacturing_date: '',
		registered_cycles: '',
		estimated_cycles: '',
		remaining_cycles: '',
		battery_model: '',
		forklift_model: '',
		total_hours: '',
		total_cycles: '',
		autonomy: '',
		client: '',
		branch: '',
		disabled: '',
		order: '',
		orderField: '',
	})
	const [columns, setColumns] = React.useState([])
	const [list, setList] = React.useState([])
	const [dateFilter, setDateFilter] = React.useState({
		initialDate: new Date().setDate(new Date().getDate() - 30),
		endDate: Date.now(),
	})
	const [selectedIds, setSelectedIds] = React.useState([])
	const [selectedRows, setSelectedRows] = React.useState([])
	const [selectAll, setSelectAll] = React.useState(false)
	const [deselectIds, setDeselectIds] = React.useState([])
	const [view, setView] = React.useState('table')
	const [registerMalfunction, setRegisterMalfunction] = React.useState({
		show: false,
		battery: {},
	})

	const [confirmationModal, setConfirmationModal] = React.useState({
		title: '',
		message: '',
		show: false,
		data: null,
	})

	const [infoModal, setInfoModal] = React.useState({
		title: '',
		message: '',
		show: false,
	})

	const [duplicateBattery, setDuplicateBattery] = React.useState()

	const loadPageData = React.useCallback(() => {
		setLoading(true)
		showLoadingBlur()

		let opt = filters
		opt.startdate = dateFilter.initialDate
		opt.finaldate = dateFilter.endDate
		opt.page = page
		opt.limit = limit

		createFetchRequest(
			'battery/reportBattery',
			'post',
			opt,
			async ({ err, result }) => {
				if (err) setList([])
				else if (!err && result) {
					let mapResult = await formatRows(result.rows)
					setList(mapResult)
					setPagination({
						totalPages: result.totalPages,
						total: result.total,
						page: result.page,
					})
					let columns = await formatColumns(profileId, (orderField, order) => {
						setFilters((prev) => ({ ...prev, orderField, order }))
					}, { callback: filterStatus, value: filters.status }, { callback: filterLastChecklist, value: filters.last_checklist })
					setColumns(columns)
					setSelectedRows([])
					setDeselectIds([])
					setSelectedIds([])
					setLoading(false)
				}

				hideLoadingBlur()
			},
		)
	}, [dateFilter, filters, hideLoadingBlur, page, profileId, showLoadingBlur])

	const onSelectRow = (row, checked) => {
		if (selectAll) {
			if (!checked) {
				setDeselectIds([...deselectIds, row.id])
				setSelectedRows(selectedRows.filter((r) => r.id !== row.id))
			} else {
				setDeselectIds(deselectIds.filter((r) => r !== row.id))
				setSelectedRows([...selectedRows], row)
			}
		} else {
			if (checked) {
				setSelectedRows([...selectedRows, row])
				setSelectedIds([...selectedIds, row.id])
			} else {
				setSelectedIds(selectedIds.filter((r) => r !== row.id))
				setSelectedRows(selectedRows.filter((r) => r.id !== row.id))
			}
		}
	}

	const onSelectAll = (checked) => {
		setDeselectIds([])
		setSelectedIds([])
		setSelectAll(checked)
	}

	const filterColumn = (filter, column) => {
		let newFilters = filters
		newFilters[column] = filter
		setFilters({ ...newFilters })
	}

	const filterStatus = (event) => {
		setFilters((prev) => ({ ...prev, status: event.target.value }))
	}

	const filterLastChecklist = (event) => {
		setFilters((prev) => ({ ...prev, last_checklist: event.target.value }))
	}

	const filterDate = (startDate, endDate) => {
		let start = new Date(startDate).getTime()
		start = start + 3 * 60 * 60 * 1000
		let end = new Date(endDate).getTime()
		end = end + 3 * 60 * 60 * 1000
		setDateFilter({ initialDate: start, endDate: end })
	}

	const filter = {
		filterDate,
		filterColumn,
		previous: () => {
			setPage(page - 1)
		},
		next: () => {
			setPage(page + 1)
		},
		lastPage: () => {
			setPage(pagination.totalPages)
		},
		firstPage: () => {
			setPage(1)
		},
		selectPage: (value) => {
			setPage(parseInt(value))
		},
		dateFilter,
		searchFilters: filters,
	}

	const onEdit = (params) => {
		setShowModalEdit({
			show: true,
			battery: params,
		})
	}

	const onDelete = React.useCallback(
		async (params) => {
			await showLoadingBlur()

			setFilters({
				id: '',
				name: '',
				serial: '',
				manufacturing_date: '',
				registered_cycles: '',
				estimated_cycles: '',
				remaining_cycles: '',
				battery_model: '',
				forklift_model: '',
				total_hours: '',
				total_cycles: '',
				autonomy: '',
				client: '',
				branch: '',
				disabled: '',
				order: '',
				orderField: '',
			})

			let isConfirmed = window.confirm(dictionary.AreYourSureToDelete)

			if (isConfirmed) {
				var context = {
					id: params.id,
				}

				await createFetchRequest(
					'battery/delete/' + params.id,
					'delete',
					context,
					async ({ err, result }) => {
						if (err) {
							console.log(err)
						}
						await loadPageData()
					},
				)
			}

			await hideLoadingBlur()
		},
		[hideLoadingBlur, loadPageData, showLoadingBlur],
	)

	const onChangeView = (value) => {
		setView(value)
	}

	const updatePage = React.useCallback(() => {
		loadPageData()
		setView('table')

		setTimeout(() => {
			setShowModalEdit({
				show: false,
				battery: {},
			})
		}, 1000)
	}, [loadPageData])

	const contructPDFDocument = () => {
		const oldRatio = window.devicePixelRatio
		window.devicePixelRatio = 1

		if (Object.keys(selectedRows).length < 1 && !selectAll) {
			alert(dictionary.noRowsSelected)
			return
		}

		showLoadingBlur()

		let id = 'cobe-battery-tags'

		const maxTagsPerPage = 8
		let tagsCounter = 0

		let tagsList = []
		const elementsArray = []

		let newSelectedRows = selectAll ? list : selectedRows
		window.alert(
			`Será gerado o PDF apenas dos dados em tela. com um total de ${newSelectedRows.length} linha(s) selecionada(s).`,
		)

		for (let batteryId in newSelectedRows) {
			let battery = newSelectedRows[batteryId]

			tagsList.push(
				<BatteryTag
					image={battery.client_image}
					code={battery.qrcode}
					name={battery.name}
					branch={battery.branch}
					forkliftModel={battery.forklift_model}
					id={battery.id}
					key={battery.id}
				/>,
			)

			tagsCounter++
		}

		for (let i = 0; i < tagsCounter; i = i + maxTagsPerPage) {
			let myArray = [...tagsList]

			myArray = myArray.slice(i, i + maxTagsPerPage)

			elementsArray.push(
				React.createElement(
					'div',
					{
						style: {
							width: '730px',
							padding: '40px',
							display: 'flex',
							flexWrap: 'wrap',
						},
						key: 'element' + i,
					},
					myArray,
				),
			)
		}

		let controller = 0
		const pdf = new jsPDF()

		const mountPdf = () => {
			const element = elementsArray[controller]

			let parentDiv = document.createElement('div')
			parentDiv.id = 'parentNode'

			document.body.appendChild(parentDiv)
			ReactDOM.render(element, parentDiv)

			html2canvas(parentDiv, { scale: 3, allowTaint: true, useCORS: true }).then(
				(canvas) => {

					const imgData = canvas.toDataURL('image/jpg', 1.0)

					pdf.addImage(imgData, 'JPEG', 0, 0, 520, 0, 'NONE', 'NONE')
					document.body.removeChild(parentDiv)

					controller++
					if (controller === elementsArray.length) {
						setTimeout(() => {
							pdf.save(id + '.pdf')
							hideLoadingBlur()
							window.devicePixelRatio = oldRatio
						}, 2000)
					} else {
						pdf.addPage()
						mountPdf()
					}
				},
			)
		}

		mountPdf()
	}

	const onViewMalfunction = (battery) => {
		setRegisterMalfunction({ show: true, battery: battery })
	}

	const onDimissMalfunction = () => {
		setRegisterMalfunction({ show: false, battery: {} })
	}

	const onDisableBattery = React.useCallback(() => {
		showLoadingBlur()
		createFetchRequest(
			`battery/disable/${confirmationModal.data.id}`,
			'put',
			null,
			({ err, result }) => {
				setConfirmationModal({ title: '', message: '', show: false })
				hideLoadingBlur()
				if (err) {
					setInfoModal({
						show: true,
						message: 'Erro ao atualizar bateria.',
						title: 'ERRO!',
					})
					return
				} else {
					setInfoModal({
						show: true,
						message: 'Bateria atualizada com sucesso.',
						title: 'OK!',
					})
					loadPageData()
					return
				}
			},
		)
	}, [confirmationModal, hideLoadingBlur, loadPageData, showLoadingBlur])

	const onDisableBatteryConfirm = React.useCallback((row) => {
		let message = row.disable
			? 'Deseja ativar esta bateria?'
			: 'Deseja desativar esta bateria?'

		setConfirmationModal({
			title: 'Atenção',
			message: message,
			show: true,
			data: row,
		})
	}, [])

	const onSaveMalfunction = React.useCallback(
		(context) => {
			showLoadingBlur()
			createFetchRequest('/malfunction', 'post', context, ({ err, result }) => {
				if (err) console.log(err)
				else {
					console.log(result)
				}

				setRegisterMalfunction({ show: false, battery: {} })
				hideLoadingBlur()
			})
		},
		[hideLoadingBlur, showLoadingBlur],
	)

	const onGenerateCSV = React.useCallback(() => {
		showLoadingBlur()
		let opt = filters
		opt.selectAll = selectAll
		opt.selectedIds = selectedIds
		opt.deselectIds = deselectIds
		opt.startdate = dateFilter.initialDate
		opt.finaldate = dateFilter.endDate
		opt.type = 'csv'

		createFetchRequest(
			'report/exportBatteryReport',
			'post',
			opt,
			async ({ err, result }) => {
				if (err) console.log(err)
				else if (!err && result) {
					setTimeout(() => {
						window.open(result)
					}, 2000)
				}

				setLoading(false)
				hideLoadingBlur()
			},
		)
	}, [
		dateFilter.endDate,
		dateFilter.initialDate,
		deselectIds,
		filters,
		hideLoadingBlur,
		selectAll,
		selectedIds,
		showLoadingBlur,
	])

	const onDuplicate = React.useCallback((item) => {
		setDuplicateBattery(item)
	}, [])

	React.useEffect(() => {
		if (duplicateBattery) {
			setView('register')
		}
	}, [duplicateBattery])

	React.useEffect(() => {
		loadPageData()
	}, [loadPageData])

	const style = {
		borderRadius: '50%',
		padding: '2px',
		width: '28px',
		height: '28px',
	}

	const actions = [
		{
			label: <FontAwesomeIcon icon={faEdit} />,
			action: onEdit,
			title: 'Editar',
			style: style,
			view: true,
		},
		{
			label: <FontAwesomeIcon icon={faTrash} />,
			action: onDelete,
			title: 'Excluir',
			style: style,
			view: true,
		},
		{
			label: <FontAwesomeIcon icon={faExclamationTriangle} />,
			action: onViewMalfunction,
			title: 'Avaria',
			style: style,
			view: true,
		},
		{
			label: <FontAwesomeIcon icon={faBan} />,
			action: onDisableBatteryConfirm,
			title: 'Desativar/Ativar',
			style: style,
			view: profileId === 1,
		},
		{
			label: <FontAwesomeIcon icon={faCopy} />,
			action: onDuplicate,
			title: 'Duplicar',
			style: style,
		},
	]

	return (
		<MainContentDiv>
			{view === 'table' && (
				<BatteryTable
					columns={columns}
					rows={list}
					period={dateFilter}
					pagination={pagination}
					filters={filter}
					loading={loading}
					name="LISTA DE BATERIAS"
					onGenerateCSV={onGenerateCSV}
					// onGeneratePDF={onGeneratePDF}
					onGenerateQRCODE={contructPDFDocument}
					selectable
					onSelect={onSelectRow}
					onSelectAll={onSelectAll}
					selectAll={selectAll}
					selectedIds={selectedIds}
					deselectIds={deselectIds}
					registerTitle="CADASTRAR"
					onChangeView={onChangeView}
					view={view}
					actions={actions}
				/>
			)}
			{view === 'register' && (
				<BatteryCreate
					view={view}
					registerTitle="CADASTRAR"
					name="LISTA DE BATERIAS"
					onChangeView={onChangeView}
					preview={duplicateBattery}
					updatePage={updatePage}
				/>
			)}
			<MalfunctionModal
				data={registerMalfunction.battery}
				show={registerMalfunction.show}
				onDimiss={onDimissMalfunction}
				onSuccess={onSaveMalfunction}
				title={`Registrar Avaria - Bateria ${registerMalfunction?.battery?.name}`}
			/>
			<EditBattery
				handleCancelEdit={() => {
					setShowModalEdit({
						show: false,
						battery: {},
					})
				}}
				updatePage={updatePage}
				editData={showModalEdit.battery}
				show={showModalEdit.show}
			/>
			<ConfirmationModal
				title={confirmationModal.title}
				message={confirmationModal.message}
				onClose={() => {
					setConfirmationModal({ title: '', message: '', show: false })
				}}
				onConfirmation={onDisableBattery}
				show={confirmationModal.show}
			/>
			<InfoModal
				modal={infoModal.message}
				onClose={() => {
					setInfoModal({ title: '', message: '', show: false })
				}}
			/>
		</MainContentDiv>
	)
}

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			hideLoadingBlur,
			showLoadingBlur,
		},
		dispatch,
	)

export default connect(null, mapDispatchToProps)(Batteries)
