import { ErrorDiv, Span, SpanError } from './style'
import { FlexColumn, MainContentDiv } from 'src/styles/globalStyles'
import { Input, debounce } from '@material-ui/core'
import React, { useCallback, useEffect, useState } from 'react'
import { createFetchRequest, formatDateFull } from 'src/genericFunctions'
import { faExclamationTriangle, faEye } from '@fortawesome/free-solid-svg-icons'
import { hideLoadingBlur, showLoadingBlur } from 'src/actions/index'
import ConfirmationModal from 'src/components/ConfirmationModal'
import CycleEdit from './CycleEdit.jsx'
import CyclesTable from 'src/components/Table'
import DateFilter from 'src/components/Filter/DateFilter'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { GetStatusFile } from 'src/services/file'
import apiPdf from 'src/services/pdf-api'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import dictionary from 'src/utils/dictionary'
import { getItem } from 'src/utils/localStorage'
import { useInfoModal } from 'src/hooks/useInfoModal'

const differenceInMMSS = (value) => {
	// Converte a diferença em milissegundos para o formato MM:SS
	const minutes = Math.floor(value / (60 * 1000))
	const seconds = Math.floor((value % (60 * 1000)) / 1000)

	// Formata o resultado no formato MM:SS
	const formattedDifference = `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`
	return formattedDifference
}


const formatColumns = (profileId, orderCallback, startDateFilter, endDateFilter) => {
	let columns = [
		{
			accessor: 'id',
			Header: dictionary.id,
			csvHeader: 'ID',
			width: 'auto',
			priority: 1,
			order: true,
			orderServer: true,
			orderCallback: orderCallback,
			showFilter: true,
		},
		{
			accessor: 'clientName',
			Header: <p>Cliente</p>,
			csvHeader: 'Cliente',
			width: 'auto',
			showColumn: profileId === 1,
			priority: 1,
			showFilter: true,
			order: true,
		},
		{
			accessor: 'branchName',
			Header: <p>Sede</p>,
			csvHeader: 'Sede',
			priority: 1,
			width: 'auto',
			showFilter: true,
			order: true,
		},
		{
			accessor: 'batteryCycle',
			Header: <p style={{ lineHeight: 'normal' }}>Bateria do <br /> Ciclo</p>,
			csvHeader: 'Bateria do Ciclo',
			priority: 1,
			width: 'auto',
			showFilter: true,
			order: true,
		},
		{
			accessor: 'batteryEmpty',
			Header: <p style={{ lineHeight: 'normal' }}>Bateria <br /> Vazia</p>,
			csvHeader: 'Bateria Vazia',
			priority: 1,
			width: 'auto',
			showFilter: true,
			order: true,
			valueFormatter: (row, value) => {
				if (value == null && Array.isArray(row.errors) && row.errors.length > 0) {
					let errors = ''

					errors += row.errors.map(x => { return `${x.description}\n`.replace(',', '') })

					return <ErrorDiv title={errors}>
						<FontAwesomeIcon icon={faExclamationTriangle} />
					</ErrorDiv>
				}
				else return <Span>{value}</Span>
			},
		},
		{
			accessor: 'batteryFull',
			Header: <p style={{ lineHeight: 'normal' }}>Bateria <br /> Cheia</p>,
			csvHeader: 'Bateria Cheia',
			priority: 1,
			width: 'auto',
			showFilter: true,
			order: true,
			valueFormatter: (row, value) => {
				if (value == null && Array.isArray(row.errors) && row.errors.length > 0) {
					let errors = ''

					errors += row.errors.map(x => { return `${x.description}\n`.replace(',', '') })

					return <ErrorDiv title={errors}>
						<FontAwesomeIcon icon={faExclamationTriangle} />
					</ErrorDiv>
				}
				else return <Span>{value}</Span>
			},
		},
		{
			accessor: 'batteryRecommended',
			Header: <p style={{ lineHeight: 'normal' }}>Bateria <br /> Recomendada</p>,
			csvHeader: 'Bateria Recomendada',
			priority: 1,
			width: 'auto',
			order: true,
			// showFilter: true,
			valueFormatter: (row, value) => {
				if (row.reason_change_battery_recomended)
					return <ErrorDiv title={row.reason_change_battery_recomended}>
						<FontAwesomeIcon icon={faExclamationTriangle} />
						<SpanError>{value}</SpanError>
					</ErrorDiv>
				else return <Span>{value}</Span>
			},
			showFilter: true,
		},
		{
			accessor: 'percentage',
			Header: <p style={{ lineHeight: 'normal' }}>% de Carga <br /> Bateria do<br /> Ciclo <br /></p>,
			csvHeader: '% de Carga Bateria do Ciclo',
			priority: 1,
			order: true,
			orderType: 'number',
			showFilter: true,
			width: 'auto'
		},
		{
			accessor: 'forkliftName',
			Header: dictionary.forklift,
			csvHeader: 'Máquina',
			showFilter: true,
			priority: 1,
			width: 'auto',
			order: true,
		},
		{
			accessor: 'applicationBattery',
			Header: <p>Aplicação</p>,
			csvHeader: 'Aplicação',
			showFilter: true,
			priority: 1,
			width: '70px',
			order: true,
		},
		{
			accessor: 'startDate',
			Header: dictionary.Start,
			csvHeader: 'Inicio',
			priority: 1,
			width: 'auto',
			order: true,
			// orderType: 'date',
			showFilter: true,
			customFilter: <DateFilter onChange={startDateFilter.callback} value={startDateFilter.value} />
		},
		{
			accessor: 'employeeStarter',
			Header: <p style={{ lineHeight: 'normal' }}>Colaborador<br />Iniciou</p>,
			csvHeader: 'Colaborador Iniciou',
			showFilter: true,
			priority: 1,
			width: '100px',
			order: true,
		},
		{
			accessor: 'endDate',
			Header: dictionary.End,
			csvHeader: 'Final',
			priority: 1,
			width: 'auto',
			order: true,
			// orderType: 'date',
			showFilter: true,
			customFilter: <DateFilter onChange={endDateFilter.callback} value={endDateFilter.value} />
		},
		{
			accessor: 'employeeCloser',
			Header: <p style={{ lineHeight: 'normal' }}>Colaborador<br />Finalizou</p>,
			csvHeader: 'Colaborador Finalizou',
			showFilter: true,
			priority: 1,
			width: '100px',
			order: true,
		},
		{
			accessor: 'usage_time',
			Header: <p style={{ lineHeight: 'normal' }}>Tempo<br />de Uso</p>,
			csvHeader: 'Tempo de Uso',
			priority: 1,
			width: '60px',
			order: true,
			showFilter: true,
		},
		{
			accessor: 'hourmeter_start',
			Header: <p style={{ lineHeight: 'normal' }}>Horímetro<br />Inicial</p>,
			csvHeader: 'Horímetro Inicial',
			priority: 1,
			width: '80px',
			order: true,
			orderType: 'number',
			showFilter: true,
		},
		{
			accessor: 'hourmeter_end',
			Header: <p style={{ lineHeight: 'normal' }}>Horímetro<br />Final</p>,
			csvHeader: 'Horímetro Final',
			priority: 1,
			width: '80px',
			order: true,
			orderType: 'number',
			showFilter: true,
		},
		{
			accessor: 'autonomy',
			Header: dictionary.Autonomy,
			csvHeader: 'Autonomia',
			priority: 1,
			width: '80px',
			showFilter: true,
			order: true,
			orderType: 'number',
			orderCallback: orderCallback,
		},
		{
			accessor: 'exchange_time_start',
			Header: <p>Tempo <br />de <br /> troca</p>,
			csvHeader: 'Tempo de troca ao entrar',
			priority: 1,
			width: '120px',
			order: true,
			orderType: 'number',
			orderCallback: orderCallback,
			valueFormatter: (row, value) => {
				if (parseInt(value) > 0)
					return differenceInMMSS(value)
				else return '00:00'
			},
			showColumn: profileId === 1,
			showFilter: true,
		},
	]

	columns = columns.sort((a, b) => { return a.priority - b.priority })

	return columns
}

async function formatRows(data) {
	let rows = []

	for (let i = 0; i < data.length; i++) {
		data[i].clientName = data[i].client?.name
		data[i].branchName = data[i].branch?.name
		data[i].batteryCycle = data[i].battery_cycle?.name
		data[i].batteryEmpty = data[i].battery_empty?.name
		data[i].batteryFull = data[i].battery_full?.name
		data[i].batteryRecommended = data[i].battery_recommended
		data[i].forkliftName = data[i].forklift?.name
		data[i].applicationBattery = data[i].application?.name
		data[i].startDate = data[i].start ? formatDateFull(data[i].start) : null
		data[i].employeeStarter = data[i].employee_starter?.name
		data[i].endDate = data[i].end ? formatDateFull(data[i].end) : null
		data[i].employeeCloser = data[i].employee_closer?.name

		if (data[i].hourmeter_start && data[i].hourmeter_end)
			data[i].autonomy = data[i].hourmeter_end - data[i].hourmeter_start

		rows.push(data[i])
	}

	return rows
}

function CycleReport({ hideLoadingBlur, showLoadingBlur }) {
	const userInfos = getItem('session')
	const { showModal } = useInfoModal()
	if (userInfos === null) window.location.reload()
	const profileId = userInfos.profile_id
	const [data, setData] = useState([])
	const [columns, setColumns] = useState([])
	const [dateFilter, setDateFilter] = useState({
		initialDate: new Date().setDate(new Date().getDate() - 30),
		endDate: Date.now(),
	})
	const [loading, setLoading] = React.useState(true)
	const [filters, setFilters] = React.useState({
		id: '',
		clientName: '',
		branchName: '',
		batteryCycle: '',
		batteryEmpty: '',
		batteryFull: '',
		forkliftName: '',
		applicationBattery: '',
		employeeStarter: '',
		employeeCloser: '',
		percentage: '',
		autonomy: '',
	})

	const [orderFilter, setOrderFilter] = React.useState({
		orderField: '',
		order: '',
	})

	const [page, setPage] = React.useState(1)
	const [pagination, setPagination] = React.useState({
		totalPages: 0,
		total: 0,
		page: 1,
	})

	const [editingCycle, setEditCycle] = useState(null)
	const [selectedIds, setSelectedIds] = React.useState([])
	const [selectAll, setSelectAll] = React.useState(false)
	const [deselectIds, setDeselectIds] = React.useState([])
	const [showDetailsFile, setShowDetailsFile] = React.useState(false)

	const [formFile, setFormFile] = React.useState({
		name: '',
		description: '',
		error: ''
	})

	const limit = 100

	//Filtros
	const filterColumn = (filter, column) => {
		filters[column] = filter
		setFilters({ ...filters })
	}

	const filterDate = (startDate, endDate) => {
		let start = new Date(startDate).getTime()
		start = start + 3 * 60 * 60 * 1000
		let end = new Date(endDate).getTime()
		end = end + 3 * 60 * 60 * 1000
		setDateFilter({ initialDate: start, endDate: end })
	}

	const filter = {
		filterDate,
		filterColumn,
		previous: () => { setPage(page - 1) },
		next: () => { setPage(page + 1) },
		lastPage: () => { setPage(pagination.totalPages) },
		firstPage: () => { setPage(1) },
		selectPage: (value) => { setPage(parseInt(value)) },
		dateFilter,
		searchFilters: filters,
	}

	const loadData = useCallback(() => {
		setLoading(true)
		showLoadingBlur()
		createFetchRequest(
			'report/cycles/'
				.concat(`?limit=${limit}&page=${page}`)
				.concat(`&orderField=${orderFilter.orderField}`).concat(`&order=${orderFilter.order}`)
				.concat(`&startdate=${dateFilter.initialDate}`)
				.concat(`&finaldate=${dateFilter.endDate}`).concat(`&client=${filters.clientName}`)
				.concat(`&branch=${filters.branchName}`).concat(`&battery_cycle=${filters.batteryCycle}`)
				.concat(`&battery_empty=${filters.batteryEmpty}`).concat(`&battery_full=${filters.batteryFull}`)
				.concat(`&application=${filters.applicationBattery}`).concat(`&employee_starter=${filters.employeeStarter}`)
				.concat(`&employee_ended=${filters.employeeCloser}`).concat(`&forklift=${filters.forkliftName}`)
				.concat(`&percentage=${filters.percentage}`).concat(`&autonomy=${filters.autonomy}`).concat(`&batteryRecommended=${filters.batteryRecommended}`).concat(`&start=${filters.startDate}`).concat(`&usage_time=${filters.usage_time}`)
				.concat(`&id=${filters.id}`).concat(`&hourmeter_start=${filters.hourmeter_start}`)
				.concat(`&hourmeter_end=${filters.hourmeter_end}`).concat(`&exchange_time_start=${filters.exchange_time_start}`),
			'get',
			null,
			async ({ err, result }) => {
				if (err) setData([])
				else if (!err && result) {
					const rows = await formatRows(result.response)
					setData(rows)
					setPagination({ totalPages: result.totalPages, total: result.total, page: page })
					if (page > parseInt(result.totalPages))
						setPage(1)
				}

				setLoading(false)
				hideLoadingBlur()
			},
		)
	}, [dateFilter, hideLoadingBlur, page, showLoadingBlur, filters, orderFilter])

	const filterValueColumn = React.useCallback((orderField, order) => {
		setOrderFilter({ orderField, order })
	}, [])

	const filterStartDate = (e) => {
		setFilters((prev) => ({ ...prev, startDate: e.target.value }))
	}

	const filterEndDate = (e) => {
		setFilters((prev) => ({ ...prev, endDate: e.target.value }))
	}

	React.useEffect(() => {
		let columns = formatColumns(profileId, filterValueColumn, { callback: filterStartDate, value: filters.startDate }, { callback: filterEndDate, value: filters.endDate })
		setColumns(columns)
	}, [filterValueColumn, profileId, filters])

	const onEditCycle = (params) => {
		console.log("AQUI")
		setEditCycle(params)
	}

	const onDismiss = () => {
		setEditCycle(null)
	}

	const onSelectRow = (row, checked) => {
		if (selectAll) {
			if (!checked)
				setDeselectIds([...deselectIds, row.id])
			else
				setDeselectIds(deselectIds.filter((r) => r !== row.id))
		} else {
			if (checked)
				setSelectedIds([...selectedIds, row.id])
			else
				setSelectedIds(selectedIds.filter((r) => r !== row.id))
		}
	}

	const onSelectAll = (checked) => {
		setDeselectIds([])
		setSelectedIds([])
		setSelectAll(checked)
	}

	const onGenerateCSV = React.useCallback(() => {
		showLoadingBlur()
		let opt = filters
		opt.selectAll = selectAll
		opt.selectedIds = selectedIds
		opt.deselectIds = deselectIds
		opt.startdate = dateFilter.initialDate
		opt.finaldate = dateFilter.endDate
		opt.type = 'csv'

		createFetchRequest(
			'report/exportCycleReport',
			'post',
			opt,
			async ({ err, result }) => {
				if (err)
					console.log(err)
				else if (!err && result) {
					setTimeout(() => {
						window.open(result)
					}, 2000)
				}

				setLoading(false)
				hideLoadingBlur()
			},
		)
	}, [dateFilter.endDate, dateFilter.initialDate, deselectIds, filters, hideLoadingBlur, selectAll, selectedIds, showLoadingBlur])

	useEffect(() => {
		loadData()
	}, [loadData])

	const editModal = (
		<CycleEdit
			handleCancelEdit={onDismiss}
			updatePage={loadData}
			editData={editingCycle}
			onDismiss={onDismiss}
		/>
	)

	const style = {
		'borderRadius': '50%',
		'padding': '2px',
		'width': '28px',
		'height': '28px'
	}

	const actions = [
		{ label: <FontAwesomeIcon icon={faEye} />, action: onEditCycle, title: 'Detalhes', style: style, view: true },
	]

	// Criação de PDF
	const changeValueFile = debounce((value, key) => {
		setFormFile((prev) => ({ ...prev, [key]: value }))
	}, 200)

	const onPdfInfo = () => {
		if (!selectAll && selectedIds.length === 0) {
			showModal({ title: 'Atenção', message: 'Não há itens selecionados', show: true })
			return
		}
		setShowDetailsFile(true)
	}

	const filesComponentRedirect = React.useCallback(() => (
		<p>Você pode acompanhar o status do seu arquivo em "Arquivos"</p>
	), [])

	const onGeneratePDF = React.useCallback(async (name, description) => {
		showLoadingBlur()
		let opt = filters
		opt.selectAll = selectAll
		opt.selectedIds = selectedIds
		opt.deselectIds = deselectIds
		opt.startdate = dateFilter.initialDate
		opt.finaldate = dateFilter.endDate
		opt.type = 'pdf'
		opt.name = name
		opt.description = description

		try {
			await apiPdf.post('/generateCyclePdf', opt)
			hideLoadingBlur()
			showModal({ title: 'Gerando PDF', message: 'O relatório está sendo gerado', show: true, component: filesComponentRedirect })
		} catch (err) {
			hideLoadingBlur()
			showModal({ title: 'Erro!', message: 'Erro ao gerar o PDF', show: true })
		}
	}, [dateFilter.endDate, dateFilter.initialDate, deselectIds, filesComponentRedirect, filters, hideLoadingBlur, selectAll, selectedIds, showLoadingBlur, showModal])

	const onValidFormFile = React.useCallback(() => {
		console.log(formFile)
		if (formFile.name.length === 0) {
			changeValueFile('O campo "Nome do arquivo" é obrigatório', 'error')
			return
		}

		onGeneratePDF(formFile.name, formFile.description)
		onCancel()
	}, [changeValueFile, formFile, onGeneratePDF])

	const onCancel = () => {
		setShowDetailsFile(false)
		setFormFile({ name: '', description: '', error: '' })
	}

	const formFileComponent = React.useMemo(() => (
		<FlexColumn style={{ padding: 10, marginBottom: 10 }}>
			<p style={{ fontWeight: 'bold' }}>Nome do arquivo *</p>
			<Input
				onChange={(e) => changeValueFile(e.target.value, 'name')}
				style={{ padding: 5, marginBottom: 5 }}
				placeholder='Nome do arquivo' />
			{formFile.error.length > 0 && (
				<p style={{ lineHeight: 'auto', color: 'red' }}>{formFile.error}</p>
			)}
			<p>Descrição do arquivo</p>
			<Input
				onChange={(e) => changeValueFile(e.target.value, 'description')}
				style={{ padding: 5, marginBottom: 5 }}
				placeholder='Descrição do arquivo' />
		</FlexColumn>
	), [changeValueFile, formFile.error])

	return (
		<MainContentDiv>
			<CyclesTable
				columns={columns}
				rows={data}
				name={'RELATÓRIO DE CICLOS'}
				period={dateFilter}
				pagination={pagination}
				filters={filter}
				loading={loading}
				actions={actions}
				selectable
				onGenerateCSV={onGenerateCSV}
				onGeneratePDF={onPdfInfo}
				onSelect={onSelectRow}
				onSelectAll={onSelectAll}
				selectAll={selectAll}
				selectedIds={selectedIds}
				deselectIds={deselectIds}
				showHiddenColumn={true}
			/>
			{editingCycle === null ? null : editModal}
			<ConfirmationModal
				title={'Detalhes do arquivo'}
				message={'Adicione informações que te ajudem a identificar o arquivo.'}
				children={
					formFileComponent
				}
				show={showDetailsFile}
				onClose={onCancel}
				onConfirmation={onValidFormFile}
			/>
		</MainContentDiv>
	)
}

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			hideLoadingBlur,
			showLoadingBlur,
		},
		dispatch,
	)

export default connect(null, mapDispatchToProps)(CycleReport)
